<template>
<div class="page list-page">
<div class="page--list">
    <el-form class="private scroll-wrapper pd4 set--bottom-foot-padding"
        :model="PB"
        :rules="rules"
        label-width="150px"
        label-suffix=":"
        ref="form"
    >
        <el-form-item label="员工编号">
            <el-input
                :value="userInfo.usercode"
                class="width3"
                disabled
            ></el-input>
        </el-form-item>

        <el-form-item label="账号" prop="account">
            <el-input
                v-model.trim="PB.account"
                class="width3"
            ></el-input>
        </el-form-item>

        <el-form-item label="手机号" prop="phone">
            <el-input
                v-model.trim="PB.phone"
                class="width3"
            ></el-input>
        </el-form-item>

        <el-form-item label="登录密码" prop="password">
            <el-input
                show-password
                v-model.trim="PB.password"
                class="width3"
            ></el-input>
            <span class="hint" style="color: orangered">只能是字母或数字，长度6-20位</span>
        </el-form-item>

        <el-form-item label="公司邮箱" prop="email">
            <el-input
                v-model.trim="PB.email"
                class="width3"
                maxlength="100"
            ></el-input>
        </el-form-item>

        <el-form-item label="是否微信认证">
            {{ userInfo.unionid ? "是" : "否" }}
            <el-button
                type="text"
                @click="showGuide=true"
                class="ml4"
            >查看认证方式</el-button>
        </el-form-item>
        <el-form-item label="是否绑定云盘账号" prop="isCloudFile" class="radioform" style="display: flex;align-items: center;">
            <div  style="display: flex;align-items: center;" class="width3">
                <el-radio v-model="PB.isCloudFile" :label="0">否</el-radio>
                <el-radio v-model="PB.isCloudFile" :label="1">是</el-radio>
            </div>
        </el-form-item>
        <el-form-item v-if="PB.isCloudFile==1" label="云盘账号" prop="cloudAccount">
            <el-input
                v-model.trim="PB.cloudAccount"
                class="width3"
            ></el-input>
        </el-form-item>
        <el-form-item v-if="PB.isCloudFile==1" label="云盘密码" prop="cloudPassword">
            <el-input
                show-password
                v-model.trim="PB.cloudPassword"
                class="width3"
            ></el-input>
            <!-- <span class="hint" style="color: orangered">只能是字母或数字，长度6-20位</span> -->
        </el-form-item>

        <WxGuide :show.sync="showGuide"></WxGuide>

        <div class="foot-bar">
            <el-button type="primary" @click="handleSubmitButton">保存</el-button>
            <el-button @click="handleNavigationBack">返回</el-button>
        </div>
    </el-form>
</div>
</div>
</template>

<script>
import WxGuide from "@/components/WxGuide/WxGuide.vue";
import forEdit from "@/mixin/forEdit";

export default {
    name: "UpdatePwd",
    components: {WxGuide},
    mixins: [forEdit],

    data() {
        const _this = this;
        async function checkSamePhone (rules, val, cb) {
            const fine = await _this.handlePhoneChange(val);
            if (fine) cb();
            else cb("手机号码重复")
        }
        async function checkSameAccount (rules, val, cb) {
            const fine = await _this.handleAccontChange(val);
            if (fine) cb();
            else cb("账号重复重复")
        }
        function pwdValidator (r, value, cb) {
            if (value === "") cb()
            if (!_this.PB.id && !(/^[0-9a-zA-z_]{6,20}$/.test(value))) 
                cb("只能是字母或数字，长度6-20位")
            else cb()
        }
        return {
            showGuide: false,
            PB: {
                account  : "",
                phone    : "",
                password : "",
                email    : "",
                isCloudFile:'',
                cloudAccount:'',
                cloudPassword:'',

            },
            rules: {
                account: [
                    { required: true, message: "请输入账号" },
                    { validator (r, v, c) {
                        if (v === "") c();
                        else if (!(/^[0-9a-zA-z_]{2,30}$/.test(v))) {
                            c("账号只能是数字或字母, 最少2位")
                        }
                        else c()
                    }},
                    { validator: checkSameAccount, trigger: "blur" }
                ],
                phone: [
                    { required: true, message: "请输入电话" },
                    { validator (r, v, c) {
                        if (v === "") c();
                        else if (!(/^\+?\d{5,15}$/.test(v))) {
                            c("请输入正确的手机号码格式")
                        }
                        else c()
                    }},
                    { validator: checkSamePhone, trigger: "blur" }
                ],
                password : { validator :pwdValidator },
                email: [
                    { required: true, message: "请输入公司邮箱" },
                    { validator (r, value, cb) {
                        if (!(/^[A-Za-z0-9\u4e00-\u9fa5._]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(value)))
                            cb("请输入正确的公司邮箱格式")
                        else cb()
                    }},
                ],
                isCloudFile: [
                    { required: true, message: "请选择是否绑定云盘账号", trigger:['change', 'blur'] },
                ],
                cloudAccount: [
                    { required: true, message: "请输入云盘账号" },
                    { validator (r, v, c) {
                        if (v === "") c();
                        // else if (!(/^[0-9a-zA-z_]{2,30}$/.test(v))) {
                        //     c("账号只能是数字或字母, 最少2位")
                        // }
                        else c()
                    }
                    },
                    // { validator: checkSameAccount, trigger: "blur" }
                ],
                cloudPassword: [
                    { required: true, message: "请输入云盘密码" },
                    { validator (r, v, c) {
                        if (v === "") c();
                        // else if (!(/^[0-9a-zA-z_]{2,30}$/.test(v))) {
                        //     c("账号只能是数字或字母, 最少2位")
                        // }
                        else c()
                    }
                    },
                    // { validator: checkSameAccount, trigger: "blur" }
                ],
                // cloudPassword : {required: true, validator :pwdValidator },
                
            }
        };
    },

    methods: {

        // 手机号验重
        async handlePhoneChange (val) {
            try {
                const res = await this.$axios({
                    url: "/api/employee/querySamePhone",
                    method: "post",
                    data: {
                        phone: val,
                        id: this.userInfo.employeeId
                    }
                })

                if (res.code === 2000) {
                    if (res.data.count) {
                        return false
                    } else return true;

                } else if (res.code !== 1003) 
                    throw res;

            } catch (reason) {
                console.warn(reason);
                return false
            }
        },

        // 账号验重
        async handleAccontChange (val) {
            try {
                const res = await this.$axios({
                    url: "/api/employee/querySameAccount",
                    method: "post",
                    data: {
                        account : val,
                        id: this.userInfo.employeeId
                    }
                })

                if (res.code === 2000) {
                    if (res.data.count) {
                        return false
                    } else return true;

                } else if (res.code !== 1003) 
                    throw res;

            } catch (reason) {
                console.warn(reason);
                return false
            }
        },

        // 点击提交按钮
        async handleSubmitButton () {
            let loading;
            try {
                await this.$refs.form.validate();
                loading = this.$loading();
                const data = {
                    empId   : this.userInfo.employeeId,
                    account : this.PB.account  || undefined,
                    phone   : this.PB.phone    || undefined,
                    email   : this.PB.email,
                    pwd     : undefined,
                    isCloudFile   : this.PB.isCloudFile,
                    cloudAccount   : this.PB.cloudAccount,
                    cloudPassword   : this.PB.cloudPassword,
                }
                if (this.PB.password) {
                    const CryptoJS = window.CryptoJS;
                    var key        = CryptoJS.enc.Utf8.parse("YIMING5AUS900001");
                    var srcs       = CryptoJS.enc.Utf8.parse(this.PB.password);
                    var encrypted  = CryptoJS.AES.encrypt(srcs, key, {mode:CryptoJS.mode.ECB,padding: CryptoJS.pad.Pkcs7});
                    data.pwd       = encrypted.toString();
                }

                const res = await this.$axios({
                    method: "post",
                    url: "/api/updateAccount",
                    data
                })
                if (res.code === 2000) {
                    // 账号和密码改了 云盘账号密码改了 重新登陆   其它情况不重新登陆
                    // 


        // this.PB.account = this.userInfo.account;
        // this.PB.phone   = this.userInfo.phone;
        // this.PB.email   = this.userInfo.email;
        // this.PB.isCloudFile   = this.userInfo.isCloudFile;
        // this.PB.cloudAccount   = this.userInfo.cloudAccount||'';
        // this.PB.cloudPassword   = this.userInfo.cloudPassword||'';

                    if( this.PB.account!==this.userInfo.account ||
                        this.PB.isCloudFile!==this.userInfo.isCloudFile||
                        this.PB.cloudAccount!==this.userInfo.cloudAccount||
                        this.PB.cloudPassword!==this.userInfo.cloudPassword
                    ){
                        this.$message({
                            message: "修改密码成功，请重新登录",
                            type: "success"
                        });
                        this.$store.dispatch("unstoreUser");
                    }else{
                        // this.userInfo.phone = 
                        this.$message({
                            message: "修改成功",
                            type: "success"
                        });
                        let userInfo = this.userInfo
                        userInfo.phone = this.PB.phone
                        userInfo.email = this.PB.email
                        localStorage.setItem("userInfo", JSON.stringify(userInfo) );
                    }
                    loading?.close()

                }  else if (res.code !== 1003) 
                    throw res;

            } catch (reason) {
                reason && this.$message({
                    message: reason || "操作失败",
                    type: "error"
                });
                console.warn(reason);
                loading?.close()
            }
        },

        // 点击返回
        handleNavigationBack () {
            this.$router.back();
        }
    },

    created () {
        this.PB.account = this.userInfo.account;
        this.PB.phone   = this.userInfo.phone;
        this.PB.email   = this.userInfo.email;
        this.PB.isCloudFile   = this.userInfo.isCloudFile;
        this.PB.cloudAccount   = this.userInfo.cloudAccount||'';
        this.PB.cloudPassword   = this.userInfo.cloudPassword||'';
    },
};
</script>

<style lang="scss" scoped>
    .foot-bar {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 0; left: 0; right: 0;
        z-index: 9;
        height: 56px;
        padding-left: 16px;
        padding-right: 16px;
        background-color: white;
        box-shadow: 0px -2px 4px 0px rgba(200, 201, 204, 0.35);
    }
    .radioform /deep/ .el-form-item__content{
        margin-left: 0 !important;
    }
</style>
