<!-- 绑定微信操作介绍 -->
<template>
    <el-dialog class="bind-wx"
        :visible="show"
        append-to-body
        @close="handleClose"
        :title="title"
        width="500px">

        <img src="./images/guide.png" alt="认证方式">

        <div slot="footer">
            <el-button @click="handleClose" type="primary">知道了</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'WxGuide',
    props: ["show"],

    data () {
        return {
            title: "查看认证方式",
        }
    },

    methods: {
        handleClose () {
            this.$emit("update:show", false);
        }
    }
}
</script>

<style lang='scss' scoped>
    /deep/ {
        .el-dialog {
            margin-top: 10vh !important;
        }
        .el-dialog__body {
            max-height: 65vh;
            padding-top: 0;
        }

    }

    img {
        display: block;
        width: 450px;
        margin: 0 auto;
    }
</style>